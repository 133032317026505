import React from "react"

import HeadSection from "../HeadSection"
import Screen from "./Screen"

import MainSlider from "./MainSlider"
import Layout from "../Layout"

export default function Home() {
  return (
    <>
      <HeadSection
        title="Политика конфиденциальности"
        description="Политика конфиденциальности сайта КаталикАвто"
      />
      <Layout>
        <Screen />
        <MainSlider />
      </Layout>
    </>
  )
}
