import React from "react"

export default function MainSlider() {
  return (
    <div className="main-slider">
      <div className="container">
        <div className="advantages-descr" style={{ fontSize: "18px" }}>
          Данная политика конфиденциальности относится к сайту с доменным именем
          katalikauto.ru и его поддоменам. Страница содержит сведения о том,
          какую информацию администрация сайта или третьи лица могут получать,
          когда пользователь (вы) посещаете его.
          <br /> <br />
          <span className="delivery-info-block__title">
            Данные, которые собираются при посещении
          </span>
          <br /> <br />
          <span className="delivery-info-block__title">
            Персональные данные
          </span>
          <br /> <br />
          Персональные данные при посещении сайта передаются пользователем
          добровольно, к ним могут относиться: имя, фамилия, отчество, номера
          телефонов, адреса электронной почты, адреса для доставки товаров или
          оказания услуг, реквизиты компании, которую представляет пользователь,
          должность в компании, которую представляет пользователь, аккаунты в
          социальных сетях, а также — прочие, заполняемые поля форм.
          <br /> <br />
          Эти данные собираются в целях оказания услуг или продажи товаров,
          возможности связи с пользователем или иной активности пользователя на
          сайте, а также, чтобы отправлять пользователю информацию, которую он
          согласился получать.
          <br /> <br />
          Мы не проверяем достоверность оставляемых данных и не гарантируем
          качественного исполнения заказов, оказания услуг или обратной связи с
          нами при предоставлении некорректных сведений.
          <br /> <br />
          Данные собираются имеющимися на сайте формами для заполнения
          (например, регистрации, оформления заказа, подписки, оставления
          отзыва, вопроса, обратной связи и иными).
          <br /> <br />
          Формы, установленные на сайте, могут передавать данные как напрямую на
          сайт, так и на сайты сторонних организаций (скрипты сервисов сторонних
          организаций).
          <br /> <br />
          Данные могут собираться через технологию cookies (куки) как
          непосредственно сайтом, так и скриптами сервисов сторонних
          организаций. Эти данные собираются автоматически, отправку этих данных
          можно запретить, отключив cookies (куки) в браузере, в котором
          открывается сайт.
          <br /> <br />
          <span className="delivery-info-block__title">
            Не персональные данные
          </span>
          <br /> <br />
          Кроме персональных данных при посещении сайта собираются не
          персональные данные, их сбор происходит автоматически веб-сервером, на
          котором расположен сайт, средствами CMS (системы управления сайтом),
          скриптами сторонних организаций, установленными на сайте. К данным,
          собираемым автоматически, относятся: IP адрес и страна его
          регистрации, имя домена, с которого вы к нам пришли, переходы
          посетителей с одной страницы сайта на другую, информация, которую ваш
          браузер предоставляет добровольно при посещении сайта, cookies (куки),
          фиксируются посещения, иные данные, собираемые счетчиками аналитики
          сторонних организаций, установленными на сайте.
          <br /> <br />
          Эти данные носят неперсонифицированный характер и направлены на
          улучшение обслуживания клиентов, улучшения удобства использования
          сайта, анализа статистики посещаемости.
          <br /> <br />
          <span className="delivery-info-block__title">
            Предоставление данных третьим лицам
          </span>
          <br /> <br />
          Мы не раскрываем личную информацию пользователей компаниям,
          организациям и частным лицам, не связанным с нами. Исключение
          составляют случаи, перечисленные ниже.
          <br /> <br />
          Данные пользователей в общем доступе
          <br /> <br />
          Персональные данные пользователя могут публиковаться в общем доступе в
          соответствии с функционалом сайта, например, при оставлении отзывов /
          вопросов, может публиковаться указанное пользователем имя, такая
          активность на сайте является добровольной, и пользователь своими
          действиями дает согласие на такую публикацию.
          <br /> <br />
          По требованию закона
          <br /> <br />
          Информация может быть раскрыта в целях воспрепятствования
          мошенничеству или иным противоправным действиям; по требованию
          законодательства и в иных случаях, предусмотренных законами РФ.
          <br /> <br />
          Для оказания услуг, выполнения обязательств
          <br /> <br />
          Пользователь соглашается с тем, что персональная информация может быть
          передана третьим лицам в целях оказания заказанных на сайте услуг,
          выполнении иных обязательств перед пользователем. К таким лицам,
          например, относятся курьерская служба, почтовые службы, службы
          грузоперевозок и иные.
          <br /> <br />
          Сервисам сторонних организаций, установленным на сайте
          <br /> <br />
          На сайте могут быть установлены формы, собирающие персональную
          информацию других организаций, в этом случае сбор, хранение и защита
          персональной информации пользователя осуществляется сторонними
          организациями в соответствии с их политикой конфиденциальности.
          <br /> <br />
          Сбор, хранение и защита полученной от сторонней организации информации
          осуществляется в соответствии с настоящей политикой
          конфиденциальности.
          <br /> <br />
          <span className="delivery-info-block__title">
            Как мы защищаем вашу информацию
          </span>
          <br />
          <br />
          Мы принимаем соответствующие меры безопасности по сбору, хранению и
          обработке собранных данных для защиты их от несанкционированного
          доступа, изменения, раскрытия или уничтожения, ограничиваем нашим
          сотрудникам, подрядчикам и агентам доступ к персональным данным,
          постоянно совершенствуем способы сбора, хранения и обработки данных,
          включая физические меры безопасности, для противодействия
          несанкционированному доступу к нашим системам.
          <br /> <br />
          Ваше согласие с этими условиями
          <br /> <br />
          Используя сайт, вы выражаете свое согласие с этой политикой
          конфиденциальности. Если вы не согласны с этой политикой, пожалуйста,
          не используйте его. Ваше дальнейшее использование сайта после внесения
          изменений в настоящую политику будет рассматриваться как ваше согласие
          с этими изменениями.
          <br /> <br />
          <span className="delivery-info-block__title">
            Отказ от ответственности
          </span>
          <br />
          <br />
          Политика конфиденциальности не распространяется ни на какие другие
          сайты и не применима к веб-сайтам третьих лиц, которые могут содержать
          упоминание о нашем сайте и с которых могут делаться ссылки на сайт, а
          также ссылки с этого сайта на другие сайты сети интернет. Мы не несем
          ответственности за действия других веб-сайтов.
          <br /> <br />
          <span className="delivery-info-block__title">
            Изменения в политике конфиденциальности
          </span>
          <br /> <br />
          Мы имеем право по своему усмотрению обновлять данную политику
          конфиденциальности в любое время. Мы рекомендуем пользователям
          регулярно проверять эту страницу
          (https://katalikauto.ru/politika-konfidenczialnosti) для того, чтобы
          быть в курсе любых изменений о том, как мы защищаем информацию о
          пользователях, которую мы собираем. Используя сайт, вы соглашаетесь с
          принятием на себя ответственности за периодическое ознакомление с
          политикой конфиденциальности и изменениями в ней.
          <br />
        </div>
      </div>
    </div>
  )
}
